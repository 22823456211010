import BaseGraph from './base_graph_controller'
import { card, primaryValue, breakDownValue } from '../helpers/tooltip_helper'

export default class IndividualPullRequestsDeliveredGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this
    const datapointMetadata = {
      zIndex: 1,
      legendIndex: 0,
      minPointLength: 0,
      yAxis: 0,
      borderRadiusTopLeft: 0,
      borderRadiusTopRight: 0,
      marker: { enabled: false }
    }

    this.totalsMap = controller.parsedGraphData.count_pull_requests.reduce((acc, val) => {
      acc[val[0]] = val[1]
      return acc
    }, {})

    const series = [
      {
        ...datapointMetadata,
        name: 'Features',
        data: controller.parsedGraphData.features_delivered,
        color: this.categoricalColors[1]
      },
      {
        ...datapointMetadata,
        name: 'Churn',
        data: controller.parsedGraphData.churn,
        color: this.categoricalColors[2]
      },
      {
        ...datapointMetadata,
        name: 'Refactor',
        data: controller.parsedGraphData.refactor,
        color: this.categoricalColors[3]
      },
      {
        ...datapointMetadata,
        name: 'Other',
        data: controller.parsedGraphData.other,
        color: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[300]
      },
      {
        name: 'Weekly avg',
        data: controller.parsedGraphData.weekly_avg_prs_merged,
        color: controller.darkMode ? controller.tailwindColors.blue[200] : controller.tailwindColors.blue[900],
        lineColor: controller.darkMode ? controller.tailwindColors.blue[200] : controller.tailwindColors.blue[900],
        zIndex: 2,
        type: 'line',
        dashStyle: 'ShortDash',
        marker: { enabled: false }
      }
    ].filter(s => !!s.data)

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(controller.parsedGraphData.max_pull_requests)

    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 20,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        title: { text: null },
        tickLength: 0,
        type: 'datetime',
        labels: {
          autoRotation: false,
          step: 2,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            return Highcharts.dateFormat('%b %d', this.value)
          }
        },
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300]
      },
      yAxis: [
        {
          gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
          title: { text: null },
          reversedStacks: false,
          max: controller.parsedGraphData.max_pull_requests,
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && controller.parsedGraphData.max_pull_requests === 0) {
                return '--'
              } else {
                return this.value
              }
            }
          },
          ...maxSettings
        }
      ],
      plotOptions: {
        column: {
          crisp: false,
          pointPadding: 0.1,
          groupPadding: 0,
          borderWidth: 2,
          borderColor: controller.darkMode ? controller.tailwindColors.gray[800] : controller.tailwindColors.white,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5
        },
        series: {
          dataLabels: {
            enabled: false
          },
          stacking: 'normal',
          cursor: controller.optionsValue.metrics_url ? 'pointer' : undefined,
          stickyTracking: false,
          point: {
            events: {
              click: function () {
                if (controller.optionsValue.metrics_url) {
                  controller.openMetricDrilldown(new Date(this.x).toISOString().split('T')[0], this.y)
                }
              }
            }
          }
        }
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        stickOnContact: true,
        formatter: function () {
          const breakDownValues = this.points.slice(0, 4).map((p, i) => {
            if (p.series.name === 'No data') {
              return null
            } else {
              const color = p.color.pattern ? p.color.pattern.color : p.color
              return breakDownValue({
                name: p.series.name,
                value: `${p.y} <span class="text-gray-600 dark:text-gray-400">(${Math.round(p.percentage || 0)}%)</span>`,
                style: `background-color: ${color};`,
                type: 'box'
              })
            }
          })

          return card({
            date: controller.parsedGraphData.date_range_display[this.x],
            incomplete: this.points[0].point.index === (controller.parsedGraphData.churn.length - 1),
            footer: controller.optionsValue.metrics_url && `<button data-value="${this.x}" data-y-value="${Math.round(this.y)}" data-action="click->individual-pull-requests-delivered-graph#openFlyout" class="btn-tertiary text-xs mt-2 w-full">View pull requests</button>`,
            primaryValues: [
              primaryValue({ name: 'Total pull requests', value: controller.totalsMap[this.x] })
            ],
            breakDownValues
          })
        }
      }
    }
  }
}
