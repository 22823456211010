export function getDateTimeStepCount (categoryLength) {
  if (categoryLength > 25) {
    return 10
  } else if (categoryLength >= 6) {
    return 3
  }
  return 1
}

export function getTickPositions (categoryLength) {
  let tickPositions
  if (categoryLength >= 12) {
    // show only 5 labels
    categoryLength -= 1
    tickPositions = [0, Math.round(categoryLength * 0.25), Math.round(categoryLength * 0.50), Math.round(categoryLength * 0.75), categoryLength]
  }
  return tickPositions
}
