import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  /*
  ---------  REQUIREMENTS FOR THIS CONTROLLER TO WORK ---------

  - controller must be initialized on a table
  - each table must have its own controller
  - each sort button must have:
    - an icon of type 'chevron_down' with the class 'sort-icon' (which must be hidden by default and for the sorted columns, in the right orientation to start with)
    - data-column-number attribute
    - data-action="click->table-sort#sort" attribute
    - example:
      <%= button_tag(type: 'button', data: { action: 'click->table-sort#sort', column_number: 1 }, class: 'text-gray-700 uppercase dark:text-gray-400 text-xs font-medium') do %>
        Dev team size
        <%= render IconComponent.new(type: :chevron_down, classes: 'h-4 w-4 inline pointer-events-none sort-icon') %>
      <% end %>
  - each row must have a data-sort-row-array attribute. This attribute must be an array of the sortable data in the row
    - example: <tr data-sort-row-array='[1, "software", "harry potter"]'> for a 3 column table with sorting in each column
    - if a non sorting column exists, you can put 0 in the array for that column or figure out the numbering accordingly
  - if you want to provide a default sort order, add a data-sort-order attribute to the table initializing the controller
  */

  connect () {
    this.table = this.element

    // if the user has set a sort order, use that
    if (this.table.dataset.sortOrder) {
      this.sortOrder = JSON.parse(this.table.dataset.sortOrder)
    }

    // get the table body
    this.tableBody = this.table.querySelector('tbody')
    if (this.table.dataset.initialSortColumn) {
      const columnNumber = Number(this.table.dataset.initialSortColumn)
      const button = this.element.querySelector(`button[data-column-number="${columnNumber}"]`)
      setTimeout(() => {
        this.sortByColumn(columnNumber, button)
      }, 0) // this ensures the sort indicator does not flash from one column to the next
    }
  }

  sort (event) {
    // get the button that was clicked
    const button = event.currentTarget

    // get the column number that we are sorting by
    const columnNumber = button.dataset.columnNumber
    this.sortByColumn(columnNumber, button)
  }

  sortByColumn (columnNumber, button) {
    // get all the rows in the table
    const rows = this.tableBody.rows

    const dataRows = []

    // This is a list of rows that are not sortable.
    const unsortableRows = []
    const fixedRows = []

    for (let i = 0; i < rows.length; i++) {
      // if the row doesn't have a data-sort-row-array attribute, it is not sortable and we move it to the end of the table
      if (rows[i].dataset.fixedRowArray) {
        fixedRows.push(rows[i])
        continue
      } else if (!rows[i].dataset.sortRowArray) {
        unsortableRows.push(rows[i])
        continue
      }

      dataRows.push({ data: JSON.parse(rows[i].dataset.sortRowArray), tr: rows[i] })
    }

    // throw error if the data has less columns than the column number
    if (dataRows.length > 0 && dataRows[0].data.length <= columnNumber) {
      throw new Error('Column number given to sort is greater than the number of columns in the data')
    }

    // if the sortOrder doesn't exist, create it
    if (!this.sortOrder) {
      this.sortOrder = dataRows[0].data.map(() => -1)
    }

    // we want to sort in the opposite direction
    // multiply by -1 converts 1 to -1 and -1 to 1
    // optional param because the button won't be there when we don't wanna allow manual sort but want an initial sort
    const direction = button?.dataset?.direction
    if (direction === 'asc') this.sortOrder[columnNumber] = -1
    else if (direction === 'desc') this.sortOrder[columnNumber] = 1

    const multiplier = this.sortOrder[columnNumber] * -1

    dataRows.sort((a, b) => {
      // multiply by multiplier to sort in the correct direction
      return (a.data[columnNumber] > b.data[columnNumber] ? 1 : -1) * multiplier
    })

    // change order of rows in table

    // add the fixed rows first
    for (let i = 0; i < fixedRows.length; i++) {
      this.tableBody.append(fixedRows[i])
    }

    // we don't need to remove the rows from the table as we are referencing them and moving them
    // append the rows in the correct order
    for (let i = 0; i < dataRows.length; i++) {
      this.tableBody.append(dataRows[i].tr)
    }

    // append the unsortable rows
    for (let i = 0; i < unsortableRows.length; i++) {
      this.tableBody.append(unsortableRows[i])
    }

    // set the columnNumber to the multiplier (1 or -1)
    this.sortOrder[columnNumber] = multiplier

    // remove the selected class from all sort buttons
    const sortIcons = this.table.querySelectorAll('.sort-icon')
    for (const sortIcon of sortIcons) {
      sortIcon.classList.add('hidden')
      sortIcon.classList.remove('rotate-180')
    }

    // add the selected class to the button that was clicked
    // get selected sort icon
    const selectedSortIcon = button?.querySelector('.sort-icon')

    selectedSortIcon?.classList?.remove('hidden')
    // if multiplier is positive (asc), add rotate-180 class
    if (multiplier === 1) selectedSortIcon?.classList?.add('rotate-180')
  }
}
