import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="comparison-view-form"
export default class extends Controller {
  static targets = ['newStrategicEventInput']

  enableStrategicEventList () {
    if (this.hasNewStrategicEventInputTarget) {
      this.newStrategicEventInputTargets.forEach((input) => {
        input.classList.add('cursor-not-allowed')
        input.classList.remove('cursor-pointer')
        input.disabled = true
        input.required = false
      })
    }

    const selectButton = document.getElementById('strategic_event_id')
    if (selectButton) {
      selectButton.classList.remove('cursor-not-allowed')
      selectButton.classList.add('cursor-pointer')
      selectButton.disabled = false
    }
  }

  enableCreateStrategicEvent () {
    if (this.hasNewStrategicEventInputTarget) {
      this.newStrategicEventInputTargets.forEach((input) => {
        input.classList.remove('cursor-not-allowed')
        input.classList.add('cursor-pointer')
        input.disabled = false
        input.required = true
      })
    }

    const selectButton = document.getElementById('strategic_event_id')
    if (selectButton) {
      selectButton.classList.add('cursor-not-allowed')
      selectButton.classList.remove('cursor-pointer')
      selectButton.disabled = true
    }
  }
}
