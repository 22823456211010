/**
 * Current period plot band helper
 */
export function currentPeriodPlotBand (controller, comparisonIndex) {
  let plotBands = []
  if (parseInt(comparisonIndex) >= 0) {
    plotBands = [
      {
        color: Highcharts.color(controller.tailwindColors.blue[100])
          .setOpacity(controller.darkMode ? 0.05 : 0.5)
          .get('rgba'),
        from: comparisonIndex - 0.5,
        to: comparisonIndex + 0.5,
        zIndex: 20,
        label: {
          align: 'center',
          inside: false,
          y: -5,
          useHtml: true,
          formatter: function () {
            return `<p class="text-[10px]" style="color: ${controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[500]};">Shown in table</p>`
          }
        }
      }
    ]
  }
  return plotBands
}
