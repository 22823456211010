/**
 * Fill color helper
 */
export function linearGradientConfig (controller) {
  return {
    fillColor: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: [
        [
          0,
          Highcharts.color(controller.tailwindColors.blue[500])
            .setOpacity(this.darkMode ? 0.3 : 0.2)
            .get('rgba')
        ],
        [
          1,
          Highcharts.color(controller.tailwindColors.blue[500])
            .setOpacity(0)
            .get('rgba')
        ]
      ]
    },
    color: controller.darkMode ? controller.tailwindColors.blue[900] : controller.tailwindColors.blue[300]
  }
}
