import { Controller } from '@hotwired/stimulus'
import theme from '../helpers/tailwind_theme'
import { FetchRequest } from '@rails/request.js'
import { configureAnnotations } from '../helpers/annotations_helper'

import {
  renderDashedJumpLines,
  renderLabeledJumpLines,
  makeMonochromeColors
} from '../helpers/highcharts_helper'

const pluralize = require('pluralize')

// this is a base controller. Do not use it directly
export default class BaseGraph extends Controller {
  static values = { chartData: Object, options: Object }
  static outlets = ['annotations']

  initializedResize = false

  connect () {
    this.chart = undefined
    this.currentWidth = 0
    try {
      this.parsedGraphData = this.chartDataValue
      this.initializeGraph()
    } catch (error) {
      // so we can see the error in headless mode
      console.error(JSON.stringify(error))
    }
  }

  disconnect () {
    document.documentElement.removeEventListener(
      'theme-change',
      this.onThemeChange
    )
    document.removeEventListener('ref_class_changed', this.initializeGraph)
    document.removeEventListener('close_tool_tips', this.closeAllToolTips)

    this.element.removeEventListener('toggle-plot-lines', this.togglePlotLines)
    this.element.removeEventListener('legend-mouse-over', this.legendMouseOver)
    this.element.removeEventListener('legend-mouse-out', this.legendMouseOut)
    this.element.removeEventListener('toggle-series', this.toggleSeries)
  }

  initialize () {
    this.renderDashedJumpLines = renderDashedJumpLines
    this.renderLabeledJumpLines = renderLabeledJumpLines
    this.makeMonochromeColors = makeMonochromeColors
    this.theme = theme
    this.initializeGraph = this.initializeGraph.bind(this)
    this.onThemeChange = this.onThemeChange.bind(this)
    this.closeAllToolTips = this.closeAllToolTips.bind(this)
    this.darkMode = localStorage.theme === 'dark'
    if (this.optionsValue?.forceTheme) {
      this.darkMode = this.optionsValue.forceTheme === 'dark'
    }
    this.colorTheme = this.getTheme()
    this.tailwindColors = theme.tailwind
    this.colorMap = [
      {
        theme: 'light',
        colors: [400, 500]
      },
      {
        theme: 'light',
        colors: [300, 400, 600]
      },
      {
        theme: 'dark',
        colors: [500, 700]
      },
      {
        theme: 'dark',
        colors: [400, 500, 700]
      }
    ]

    this.baseConfigOptions = {
      lang: { noData: null },
      title: {
        text: null
      },
      legend: {
        enabled: false
      }
    }

    this.baseToolTipConfig = {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      borderRadius: 0,
      borderWidth: 0,
      shape: 'rect',
      outside: false,
      useHTML: true,
      shadow: false,
      style: {
        padding: 0
      },
      hideDelay: 100,
      stickOnContact: false,
      followPointer: false,
      positioner: function (w, h, point) {
        // if the tooltip is going out of the chart, then move it to the left, otherwise keep the orig plotX position
        // use point.plotX instead of this.chart.hoverPoint.plotX as hoverPoint is not always available
        let tooltipX = point.plotX
        let tooltipY = -(h / 2)
        if (tooltipX + w > this.chart.plotWidth) {
          tooltipX = this.chart.plotWidth - w
        } else {
          const offset = point.plotX - w / 3
          if (offset < 0) {
            tooltipX = 0
          } else {
            tooltipX = offset
          }
        }
        if ((this.chart.plotHeight * 1.6) < h) {
          tooltipY = -(h * 0.9)
        }
        return {
          x: tooltipX,
          y: tooltipY
        }
      }
    }

    this.toolTipWrapper = contents => {
      return `<div class="shadow-lg p-2 rounded-md bg-white dark:bg-gray-700 relative z-20">${contents}</div>`
    }

    document.documentElement.addEventListener(
      'theme-change',
      this.onThemeChange
    )
    document.addEventListener('ref_class_changed', this.initializeGraph)

    document.addEventListener('close_tool_tips', this.closeAllToolTips)

    this.element.addEventListener('toggle-plot-lines', this.togglePlotLines.bind(this))
    this.element.addEventListener('legend-mouse-over', this.legendMouseOver.bind(this))
    this.element.addEventListener('legend-mouse-out', this.legendMouseOut.bind(this))
    this.element.addEventListener('toggle-series', this.toggleSeries.bind(this))
  }

  getTickInterval (data) {
    let tickInterval = 60 * 60 * 24 * 1000 * 7
    if (data.length > 1) {
      const secondTimestamp = data[1][0]
      const firstTimestamp = data[0][0]
      if (secondTimestamp && firstTimestamp) {
        tickInterval = secondTimestamp - firstTimestamp
      }
    }
    return tickInterval
  }

  getEndDate (unixDate, granularity) {
    const date = new Date(unixDate)
    if (granularity.toLowerCase() === 'week') {
      date.setUTCDate(date.getUTCDate() + 6)
    } else if (granularity.toLowerCase() === 'month') {
      // set date to the month's end
      date.setUTCMonth(date.getUTCMonth() + 1)
      date.setUTCDate(0)
    }

    return date
  }

  getTheme () {
    if (this.optionsValue.forceTheme?.length) return theme[this.optionsValue.forceTheme]

    return this.storageAvailable()
      ? theme[localStorage.theme] || theme.light
      : theme.light
  }

  // localStorage is not available in headless chrome
  storageAvailable () {
    try {
      const storage = window.localStorage
      const x = '__storage_test__'
      storage.setItem(x, x)
      storage.removeItem(x)
      return true
    } catch (e) {
      return false
    }
  }

  setBreakdownColors () {
    this.breakdownColors = [
      this.categoricalColors[1],
      this.categoricalColors[2],
      this.categoricalColors[3],
      this.categoricalColors[4],
      this.categoricalColors[5],
      this.categoricalColors[6],
      this.categoricalColors[7],
      this.categoricalColors[8],
      this.categoricalColors[9],
      this.categoricalColors[10],
      this.categoricalColors[11],
      this.categoricalColors[12]
    ]
  }

  onThemeChange () {
    this.colorTheme = this.getTheme()
    this.darkMode = localStorage.theme === 'dark'
    this.setBreakdownColors()

    this.initializeGraph()
  }

  initializeGraph () {
    const controller = this
    this.categoricalColors = this.darkMode ? this.tailwindColors['categorical-dark'] : this.tailwindColors['categorical-light']
    this.setBreakdownColors()

    try {
      if (!this.prepareGraphSchema) {
        return
      }

      let chartConfig = {
        credits: {
          enabled: false
        },
        ...this.prepareGraphSchema()
      }

      if (this.optionsValue.annotations_enabled) {
        if (!chartConfig.chart.events) {
          chartConfig.chart.events = {} // add events if they don't exist
        }

        // this wraps the formatter call so that we can inject a call to the mouseoverhander in the base controller
        chartConfig = configureAnnotations(this, chartConfig)
        const originalFormatter = chartConfig.tooltip.formatter
        chartConfig.tooltip.formatter = function () {
          controller.mouseOverHandler(this.x)
          return originalFormatter.call(this)
        }
        chartConfig.tooltip.crosshairs = true

        chartConfig.legend = {
          ...chartConfig.legend,
          align: 'left',
          verticalAlign: 'top',
          layout: 'horizontal'
        }
      }

      if (this.chart) {
        this.chart.destroy()
        this.chart = undefined
      }

      const hasOverMax = chartConfig.series.find((series) => (series.data && series.data.length > 200))

      if (hasOverMax) {
        if (chartConfig.plotOptions.series) chartConfig.plotOptions.series.animation = false
        if (chartConfig.plotOptions.column) chartConfig.plotOptions.column.animation = false
      }

      this.chart = Highcharts.chart(this.element, chartConfig)

      this.element.style.overflow = 'visible'
    } catch (error) {
      console.error(error)
    }
  }

  mouseOverHandler (xPos) {
    if (this.hasAnnotationsOutlet) this.annotationsOutlet.categoryMouseOver(xPos)
  }

  closeAllToolTips () {
    this.chart?.tooltip.hide(0)
  }

  getNumericDisplay (value) {
    // round and check if the number is an integer, if so we'll remove the decimal point, otherwise we'll show it
    return Number.isInteger(Number(value.toFixed(1))) ? value.toFixed(0) : value.toFixed(1)
  }

  getLabelSpacing (tooltipLabel) {
    return (tooltipLabel.indexOf('%') === 0) ? 'space-x-0' : 'space-x-1'
  }

  getAvgChipLabel (pointValue) {
    const displayUnit = this.optionsValue.unitFormat === '%' ? '%' : ''
    const tooltipLabel = this.optionsValue.tooltipOverride || displayUnit
    return tooltipLabel !== '%' ? pluralize(tooltipLabel, pointValue) : tooltipLabel
  }

  makePlotLinesAvgChip () {
    const tooltipLabel = this.getAvgChipLabel(this.optionsValue.avg)

    const chipStyle = this.optionsValue.global
      ? 'text-indigo-600 dark:text-indigo-400 border-indigo-500 dark:border-indigo-400'
      : 'text-blue border-blue-500'
    return `<div class="font-xs px-2 py-0.5 ${chipStyle} bg-white dark:bg-gray-800 border rounded-full">
      <div class="flex ${this.getLabelSpacing(tooltipLabel)}"><span>Avg ${this.getNumericDisplay(this.optionsValue.avg)}</span><span>${tooltipLabel}</span></div>
    </div>`
  }

  makeToolTipPosition (labelWidth, labelHeight, point, chart) {
    const padding = 30
    // chart bounding box
    const { left, top, width } = chart.container.getBoundingClientRect()

    const actualLeftPos = left + padding + point.plotX
    const rightChartEdge = width + left
    const percentLeft = 1 - (rightChartEdge - actualLeftPos) / actualLeftPos

    const actualTopPos = top + point.plotY - labelHeight - padding
    const topChartEdge = top

    const x = actualLeftPos - labelWidth * percentLeft
    const y =
      actualTopPos >= topChartEdge
        ? actualTopPos
        : actualTopPos + labelHeight + padding + 10
    return { x, y }
  }

  legendMouseOver (evt) {
    const name = evt.detail.name
    if (!this.chart || !this.chart.series.length) {
      return
    }

    this.chart.series.forEach(series => {
      if (series.name === name) { return }
      series.setState('inactive')
    })
  }

  legendMouseOut () {
    if (!this.chart || !this.chart.series.length) {
      return
    }

    this.chart.series.forEach(series => { series.setState('') })
  }

  toggleSeries (evt) {
    const name = evt.detail.name
    if (!this.chart || !this.chart.series.length) {
      return
    }

    const series = this.chart.series.find(series => series.name === name)
    series?.setVisible(!series?.visible)
  }

  togglePlotLines () {
    if (!this.chart || !this.chart.yAxis.length) {
      return
    }

    const visible = this.chart.yAxis[0].plotLinesAndBands.length > 0
    if (visible) {
      let i = this.chart.yAxis[0].plotLinesAndBands.length
      while (i--) {
        this.chart.yAxis[0].plotLinesAndBands[i].destroy()
      }
    } else {
      if (this.optionsValue.avg || this.optionsValue.percentiles) {
        // used for the older graphs with options {} values
        this.chart.yAxis[0].update({
          plotLines: this.makePlotLines()
        })
      } else if (this.plotLines) {
        // used with newer charts that have plotLines defined
        this.chart.yAxis[0].update({
          plotLines: this.plotLines
        })
      }
    }
  }

  makeGlobalPlotLineColor () {
    return this.darkMode
      ? this.tailwindColors.indigo[500]
      : this.tailwindColors.indigo[600]
  }

  makePlotLineColor () {
    if (this.optionsValue.goal) {
      return this.tailwindColors.yellow[400]
    } else if (this.optionsValue.global) {
      return this.makeGlobalPlotLineColor()
    }
    return this.colorTheme.primary
  }

  makePlotLines () {
    if (this.optionsValue.global && this.optionsValue.percentiles) {
      const percentilesGlobal = this.optionsValue.percentiles

      return Object.keys(percentilesGlobal).map(key => {
        const value = percentilesGlobal[key]
        const label = this.makePercentileLabel(key)
        const color = this.makePercentileLineColor(key)

        return {
          zIndex: 10,
          color,
          value,
          dashStyle: 'Dash',
          width: 1,
          label: {
            style: {
              height: '20px'
            },
            x: 0,
            y: 2,
            verticalAlign: 'middle',
            useHTML: true,
            text: label
          }
        }
      })
    } else if (this.optionsValue.avg) {
      const avg = this.getNumericDisplay(this.optionsValue.avg)
      const chipHtml = this.makePlotLinesAvgChip()

      return [
        {
          zIndex: 10,
          color: this.makePlotLineColor(),
          value: avg,
          dashStyle: 'Dash',
          width: 1,
          label: {
            style: {
              height: '21px'
            },
            x: 0,
            y: 2,
            verticalAlign: 'middle',
            useHTML: true,
            text: chipHtml
          }
        }
      ]
    }
  }

  openTurboStream (url, params) {
    const query = Object.keys(params).reduce((hash, key) => {
      if (params[key]) hash[key] = params[key]
      return hash
    }, {})
    const request = new FetchRequest('get', url, { responseKind: 'turbo-stream', query })
    request.perform()
  }

  openFlyout (evt) {
    if (this.optionsValue.metrics_url.includes('deployment_report_flyout')) {
      this.openDeploymentDrilldown(new Date(Number(evt.target.dataset.value)).toISOString().split('T')[0])
    } else {
      this.openMetricDrilldown(new Date(Number(evt.target.dataset.value)).toISOString().split('T')[0], evt.target.dataset.yValue)
    }
  }

  openMetricDrilldown (date, metricAvg = null) {
    this.openTurboStream(this.optionsValue.metrics_url, {
      breakdown_date: date,
      git_user_group_id: this.optionsValue.git_user_group_id,
      repository_names: this.optionsValue.repository_names,
      primary_metric_avg: metricAvg
    })
  }

  openDeploymentDrilldown (date) {
    this.openTurboStream(this.optionsValue.metrics_url, {
      breakdown_date: date,
      git_user_group_id: this.optionsValue.git_user_group_id,
      repository_names: this.optionsValue.repository_names,
      metric: this.optionsValue.metric,
      date_range: this.optionsValue.date_range,
      date_granularity: this.optionsValue.date_granularity
    })
  }

  addNoDataSeriesToSeries (series, data = []) {
    // add the zeroes series for min point height
    series.push({
      name: 'No data', // for min point height incase of zeroes
      data,
      color: this.darkMode ? this.tailwindColors.gray[600] : this.tailwindColors.gray[300],
      borderRadiusTopLeft: 3,
      borderRadiusTopRight: 3
    })
  }

  getLastDataPointPattern (config) {
    return {
      color: {
        pattern: {
          path: 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2',
          width: 4,
          height: 4,
          color: config.bg || config.color
        }
      },
      states: {
        hover: {
          color: config.hover
        }
      }
    }
  }

  getYAxisMaxAndTickAmountSettings (total) {
    return {
      min: 0,
      max: !total ? 4 : undefined,
      maxPadding: 0.1,
      tickAmount: !total ? 4 : undefined
    }
  }

  daysOrHoursString (days) {
    let unit = 'day'
    let value
    if (days < 1) {
      value = this.daysToHours(days)
      if (!value) {
        value = this.daysToMinutes(days)
        unit = 'min'
      } else {
        unit = 'hr'
      }
    } else {
      value = Math.round(days * 10) / 10
    }
    if (value !== 1) unit += 's'
    return `${value} ${unit}`
  }

  daysToHours (days) {
    return Math.round(days * 24)
  }

  daysToMinutes (days) {
    return Math.round(days * 24 * 60)
  }

  formatNumberWithCommas (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  fetchSelectedGraphPeriod (controller, selectedIndex) {
    let graphUrl = controller.optionsValue.url
    if (graphUrl.indexOf(window.location.origin) === -1) {
      graphUrl = window.location.origin + '/' + graphUrl
    }
    const url = new URL(graphUrl)
    const searchParams = new URLSearchParams(url.search)
    searchParams.set('kpi_table_date', controller.parsedGraphData.kpi_table_dates[selectedIndex])
    const periodSelectUrl = [
      url.origin,
      url.pathname,
      '?',
      searchParams
    ].join('')
    window.location.href = periodSelectUrl
  }
}
