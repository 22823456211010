// NOTE: this is not for a standard comparison chart
import BaseGraph from './base_graph_controller'
import { card, breakDownValue, exclamationSvg } from '../helpers/tooltip_helper'

// Connects to data-controller="strategic-event-comparison-chart"
export default class extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    const positivePercentClasses = 'w-fit text-xs font-semibold px-2 py-1 rounded-md text-teal-600 bg-teal-50 dark:bg-teal-500/30 dark:text-teal-100'
    const negativePercentClasses = 'w-fit text-xs font-semibold px-2 py-1 rounded-md text-red-600 bg-red-50 dark:bg-red-500/30 dark:text-red-100'

    // lower lead time and cost is better
    const flippedPercent = this.optionsValue.format === '$' || this.optionsValue.format === 'days'

    this.USDFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    })

    if (!this.seriesVisibility) {
      this.seriesVisibility = {}

      if (controller.optionsValue.type === 'spline') {
        controller.parsedGraphData.data.forEach(dataPoint => { controller.seriesVisibility[dataPoint.name] = true })
      } else {
        controller.parsedGraphData.current.data.forEach(dataPoint => { controller.seriesVisibility[dataPoint.name] = true })
      }
    }

    let series
    if (controller.optionsValue.type === 'spline') {
      series = controller.formatSplineSeries(
        controller.parsedGraphData.data,
        controller.parsedGraphData.categories
      )
    } else if (controller.optionsValue.type === 'column') {
      series = controller.formatColumnSeries(controller.parsedGraphData)
    }

    let type = controller.optionsValue.type
    // for active engineers chart
    if (controller.optionsValue.format === 'step' && type === 'spline') type = 'line'

    let sum = 0
    if (controller.optionsValue.type === 'spline') {
      controller.parsedGraphData.data.forEach((item) => {
        sum += item.data.reduce((acc, value) => acc + value, 0)
      })
    } else {
      controller.parsedGraphData.current.data.forEach((item) => { sum += item.y })
      controller.parsedGraphData.baseline.data.forEach((item) => { sum += item.y })
    }
    const maxSettings = this.getYAxisMaxAndTickAmountSettings(sum)

    let plotLines = []
    let plotBands = []
    if (controller.optionsValue.type === 'spline') {
      plotBands = this.strategicEventPlotBands()
      plotLines = this.strategicEventPlotLines()
    }

    return {
      chart: {
        type,
        backgroundColor: controller.colorTheme.backgroundColor,
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 30,
        spacingBottom: 0,
        height: 250
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'category',
        categories: controller.parsedGraphData.categories,
        title: { text: null },
        tickLength: 0,
        labels: {
          enabled: true,
          useHTML: true,
          formatter: function () {
            let percentHtml = ''
            if (controller.optionsValue.type === 'column') {
              const percent = controller.percentChange[this.value] || 0
              percentHtml = `<div class="${(percent < 0 ^ flippedPercent) ? negativePercentClasses : positivePercentClasses}">${percent > 0 ? '+' : ''}${percent}%</div>`
            }
            return `<div class="flex flex-col gap-1 items-center">
            <div class="text-xs text-gray-500 dark:text-gray-400 ${controller.optionsValue.type === 'column' ? 'font-semibold' : ''}">${this.value}</div>
            ${percentHtml}
            </div`
          }

        },
        lineWidth: 0,
        plotLines,
        plotBands
      },
      yAxis: {
        title: { text: null },
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[700] : controller.tailwindColors.gray[200],
        softMax: 1,
        labels: {
          enabled: true,
          // add $ in front of y-axis labels
          formatter: function () {
            if (sum === 0) {
              return '--'
            } else if (controller.optionsValue.format === 'days') {
              return `${this.value}d`
            } else {
              return controller.formatValue(this.value)
            }
          },
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        ...maxSettings
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          groupPadding: 0.1,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5,
          borderColor: 'transparent',
          minPointLength: 5,
          colorByPoint: true,
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'justify',
            formatter: function () {
              return controller.formatValue(this.y > 0 ? this.y : '--')
            },
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.white
                : controller.tailwindColors.black
            }
          }
        },
        spline: {
          marker: {
            enabled: false,
            symbol: 'circle'
          }
        },
        line: {
          marker: {
            enabled: false,
            symbol: 'circle'
          },
          step: 'center'
        },
        series: {
          connectNulls: true
        }
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        outside: false,
        formatter: function () {
          if (controller.optionsValue.type === 'spline') {
            let quarterInProgressHtml = ''
            if (this.point.index === this.series.points.length - 1) {
              quarterInProgressHtml = `<div class="mt-2 flex items-center gap-1 p-1 rounded text-yellow-500 bg-yellow-500/10">
                <p class="block">${exclamationSvg}</p>
                <p>${controller.optionsValue.date_granularity} in progress</p>
              </div>`
            }

            return card({
              date: this.key,
              header: `${quarterInProgressHtml}<div class="font-medium mt-2 text-gray-700 dark:text-gray-300">${controller.optionsValue.title}</div>`,
              breakDownValues: [breakDownValue({
                name: this.series.name,
                value: controller.formatValue(this.y, true),
                style: `background-color:${this.series.color};`,
                type: 'box'
              })]
            })
          } else {
            return `<div class="shadow-lg p-3 rounded-md bg-white dark:bg-gray-700 min-w-[13rem] z-50">
            <div class="font-medium text-gray-700 dark:text-gray-300">${controller.optionsValue.title}</div>
            <div class="flex justify-between items-center gap-2 mt-3">
              <div class="flex items-center gap-1">
                <div class="w-3 h-3 rounded-sm" style="background-color:${this.point.color}"></div>
                <div>
                  <span class="font-medium text-black dark:text-white">${this.point.name}</span>
                  <span class="text-gray-500 dark:text-gray-400">${this.series.name}</span>
                </div>
              </div>
              <div class="font-semibold text-black dark:text-white">${controller.formatValue(this.y, true)}</div>
            </div>
          </div>
          `
          }
        }
      }
    }
  }

  formatValue (value, includeSuffix = false) {
    let valueText = '--'

    if (value != null) {
      if (this.optionsValue.format === '$') {
        valueText = (value === '--') ? '$--' : this.USDFormatter.format(value)
      } else if (value !== '--') {
        // round to 1 decimal place. we send the value with 2 decimal places for lead time for better granularity
        valueText = (Math.round(value * 10) / 10).toString()
      }
    }

    // Determine suffix based on options or include '%' for percentage format
    const suffix = includeSuffix ? this.optionsValue.suffix : (this.optionsValue.format === '%' ? '%' : '')

    return `${valueText}${suffix}`
  }

  formatColumnSeries (data) {
    // duping the data to avoid color change bugs when re-rendering due to dark/light mode changes
    const current = { ...data.current }
    const baseline = { ...data.baseline }

    this.percentChange = {}

    // set colors
    for (let i = 0; i < current.data.length; i++) {
      current.data[i].color = this.tailwindColors['categorical-dark'][(i % 12) + 1] // +1 since we start at 1
      baseline.data[i].color = this.tailwindColors['categorical-light'][(i % 12) + 1] // +1 since we start at 1
      this.percentChange[current.data[i].name] = baseline.data[i].percent_change
    }

    // filter based on visibility
    current.data = current.data.filter(item => this.seriesVisibility[item.name])
    baseline.data = baseline.data.filter(item => this.seriesVisibility[item.name])

    // sort based on current data value
    current.data = current.data.sort((a, b) => {
      // Treat 0 as greater than any other number
      if (a.y === 0) return 1
      if (b.y === 0) return -1

      return this.optionsValue.sort === 'desc' ? b.y - a.y : a.y - b.y
    })

    // Create a map (hash) of the sorted order
    const currentOrder = new Map(current.data.map((item, index) => [item.name, index]))

    // Sort baseline data based on the current order
    baseline.data = baseline.data.sort((a, b) => currentOrder.get(a.name) - currentOrder.get(b.name))

    return [baseline, current]
  }

  formatSplineSeries (series, categories) {
    if (!series.length) return [{}]

    const zoneIndex = categories.length - 2
    const strategicEventIndex = this.parsedGraphData.strategic_event_index

    for (let i = 0; i < series.length; i++) {
      const item = series[i]
      item.zoneAxis = 'x'
      item.zones = [
        {
          value: strategicEventIndex,
          color: this.tailwindColors['categorical-light'][(i % 12) + 1]
        },
        {
          value: zoneIndex,
          color: this.tailwindColors[`categorical-${strategicEventIndex < zoneIndex ? 'dark' : 'light'}`][(i % 12) + 1]
        },
        {
          dashStyle: 'ShortDash',
          color: this.tailwindColors['categorical-dark'][(i % 12) + 1]
        }
      ]
      item.visible = this.seriesVisibility[item.name]
    }

    return series
  }

  strategicEventPlotLines () {
    return [
      {
        color: this.darkMode ? this.tailwindColors.gray[400] : this.tailwindColors.gray[300],
        value: this.parsedGraphData.strategic_event_index,
        dashStyle: 'ShortDash',
        width: 2,
        label: {
          y: -10,
          x: -0.5,
          textAlign: 'center',
          useHTML: true,
          rotation: 0,
          text: `
          <div class="grid justify-items-center">
            <div class="text-xs font-medium px-2 text-gray-500 dark:text-gray-400 text-ellipsis overflow-hidden">${this.optionsValue.strategic_event_name}</div>
            <div class="bg-gray-500 dark:bg-gray-400 w-2 h-2 rounded-full"></div>
          </div>
        `
        }
      }
    ]
  }

  strategicEventPlotBands () {
    const controller = this
    const baselineIndices = this.optionsValue.baseline_period_zone_indices
    const valueIndices = this.optionsValue.value_period_zone_indices
    const plotBands = []
    if (baselineIndices[0] < baselineIndices[1]) {
      plotBands.push({
        color: Highcharts.color(this.tailwindColors.gray[200])
          .setOpacity(this.darkMode ? 0.05 : 0.15)
          .get('rgba'),
        from: baselineIndices[0],
        to: baselineIndices[1],
        label: {
          align: 'center',
          y: -10,
          useHtml: true,
          formatter: function () {
            return `<p class="text-xs" style="color: ${controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[500]};">Before</p>`
          }
        }
      })
    }

    if (valueIndices[0] < valueIndices[1]) {
      plotBands.push({
        color: Highcharts.color(this.tailwindColors.blue[200])
          .setOpacity(this.darkMode ? 0.1 : 0.5)
          .get('rgba'),
        from: valueIndices[0],
        to: valueIndices[1],
        label: {
          align: 'center',
          y: -10,
          useHtml: true,
          formatter: function () {
            return `<p class="text-xs" style="color: ${controller.tailwindColors.blue[500]};">${controller.optionsValue.value_period_label}</p>`
          }
        }
      })
    }

    return plotBands
  }

  legendMouseOver (name) {
    if (this.optionsValue.type === 'spline') {
      // set name to everything except last 2 characters (those are used for baseline vs current column hovers)
      name = name.slice(0, -2)
      this.chart.series.forEach(series => {
        if (series.name === name) { return }
        series.setState('inactive')
      })
    } else {
      // check if the last 2 characters are '#B' to know if it's a baseline
      let seriesToHover = this.chart.series[1]
      const type = name.slice(-2)
      if (type === '#B') {
        seriesToHover = this.chart.series[0]
      }
      // set name to everything except last 2 characters (series identifying characters)
      name = name.slice(0, -2)
      const point = seriesToHover.data.find(dataPoint => dataPoint.name === name)
      point?.setState('hover')
      if (point) this.chart.tooltip.refresh(point)
    }
  }

  legendMouseOut () {
    if (this.optionsValue.type === 'spline') {
      this.chart.series.forEach(series => series.setState(''))
    } else {
      this.chart.series[0].data.forEach(point => { point.setState('normal') })
      this.chart.tooltip.hide()
    }
  }

  legendClicked (name) {
    if (this.optionsValue.type === 'spline') {
      this.chart.series.forEach(series => {
        if (series.name === name) {
          series.setVisible(!series.visible)
        }
      })
    } else {
      // can also use this for spline but the visibility offers a nice animation
      this.seriesVisibility[name] = !this.seriesVisibility[name]
      this.chart.update(this.prepareGraphSchema())
    }
  }
}
