import { Controller } from '@hotwired/stimulus'
export default class GitConnectController extends Controller {
  static targets = ['connectGitHubInfoModal', 'connectBitbucketInfoModal', 'connectGitlabInfoModal', 'connectAzureDevOpsInfoModal']

  showConnectGitHubInfoModal () {
    this.connectGitHubInfoModalTarget.classList.remove('hidden')
  }

  hideConnectGitHubInfoModal () {
    this.connectGitHubInfoModalTarget.classList.add('hidden')
  }

  showConnectBitbucketInfoModal () {
    this.connectBitbucketInfoModalTarget.classList.remove('hidden')
  }

  hideConnectBitbucketInfoModal () {
    this.connectBitbucketInfoModalTarget.classList.add('hidden')
  }

  showConnectGitlabInfoModal () {
    this.connectGitlabInfoModalTarget.classList.remove('hidden')
  }

  hideConnectGitlabInfoModal () {
    this.connectGitlabInfoModalTarget.classList.add('hidden')
  }

  showConnectAzureDevOpsInfoModal () {
    this.connectAzureDevOpsInfoModalTarget.classList.remove('hidden')
  }

  hideConnectAzureDevOpsInfoModal () {
    this.connectAzureDevOpsInfoModalTarget.classList.add('hidden')
  }
}
