export const exclamationSvg = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.00002 6V7.33333M8.00002 10H8.00669M3.38122 12.6667H12.6188C13.6452 12.6667 14.2867 11.5556 13.7735 10.6667L9.15472 2.66667C8.64152 1.77778 7.35852 1.77778 6.84532 2.66667L2.22652 10.6667C1.71332 11.5556 2.35482 12.6667 3.38122 12.6667Z" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`

export function card ({ date, header = '', footer = '', primaryValues = [], breakDownValues = [], incomplete = false, granularity = 'week' }) {
  const granularityText = granularity.replace(/\w\S*/g, text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase())
  const dateMarkup = `<p class="text-gray-500 dark:text-gray-300 font-medium text-xs">${date}</p>`
  const incompleteMarkup = `<div class="mt-2 flex items-center gap-1 p-1 rounded text-yellow-500 bg-yellow-500/10"> <p>${exclamationSvg}</p> <p>${granularityText} is incomplete</p> </div>`
  const primaryValuesMarkup = `<div class="mt-1">${primaryValues.join('')}</div>`
  const breakDownValuesMarkup = `<div class="mt-2">${breakDownValues.join('')}</div>`

  return `<div class="text-black dark:text-white shadow-lg p-2 rounded-md bg-white dark:bg-gray-700 min-w-52 z-50 border border-gray-200 dark:border-gray-600">
    ${date ? dateMarkup : ''}
    ${header || ''}
    ${incomplete ? incompleteMarkup : ''}
    ${primaryValues.length ? primaryValuesMarkup : ''}
    ${primaryValues.length && breakDownValues.length ? '<div class="mt-2 h-[1px] bg-gray-300 dark:bg-gray-600"></div>' : ''}
    ${breakDownValues.length ? breakDownValuesMarkup : ''}
    ${footer || ''}
  </div>`
}

export function primaryValue ({ name, value }) {
  return `<div class="pt-1 flex gap-2 justify-between items-center">
  <p class="text-gray-500 dark:text-gray-300 font-medium text-xs dd-privacy-mask">${name}</p>
  <p class="text-gray-900 dark:text-white font-medium text-sm">${value}</p>
</div>`
}

export function breakDownValue ({ name, value, style, cssClass, type }) {
  let typeMarkup = ''
  switch (type) {
    case 'line':
      typeMarkup = `<div class="w-3 font-bold ${cssClass}" style="height: 2px; ${style}"></div>`
      break
    case 'dash':
      typeMarkup = `<div class="h-3 w-3 font-bold ${cssClass}" style="${style}">--</div>`
      break
    case 'none':
      typeMarkup = ''
      break
    default:
      typeMarkup = `<div class="h-3 w-3 shrink-0 rounded-sm ${cssClass}" style="${style}"></div>`
  }
  return `<div class="py-1 flex gap-2 justify-between items-center">
    <div class="flex gap-2 items-center whitespace-normal">
      ${typeMarkup}
      <p class="text-gray-500 dark:text-gray-300 font-medium text-xs dd-privacy-mask">${name}</p>
    </div>
    <p class="text-gray-900 dark:text-white font-medium text-sm whitespace-nowrap">${value}</p>
  </div>`
}
