import BaseGraph from './base_graph_controller'
import { card, primaryValue } from '../helpers/tooltip_helper'
import { linearGradientConfig } from '../helpers/fill_color_helper'

export default class TeamCodeReviewGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    // sort the data by the time value
    this.parsedGraphData.data.sort((a, b) => a[0] - b[0])
    const total = controller.parsedGraphData.data.reduce((acc, val) => acc + val[1], 0)

    const tickInterval = this.getTickInterval(controller.parsedGraphData.data)
    const index = controller.parsedGraphData.data.length ? controller.parsedGraphData.data.length - 2 : 0
    const zoneValue = index > 0 ? this.parsedGraphData.data[index][0] : 0
    const series = [
      {
        data: this.parsedGraphData.data,
        name: 'Code Review Participation',
        marker: {
          enabled: false
        },
        lineColor: this.colorTheme.primary,
        color: this.colorTheme.primary,
        type: 'areaspline',
        stacking: 'stream',
        zoneAxis: 'x',
        zones: [
          {
            value: zoneValue,
            ...linearGradientConfig(controller)
          },
          {
            dashStyle: 'ShortDash',
            ...linearGradientConfig(controller)
          }
        ],
        point: {
          events: {
            click: function () {
              controller.openMetricDrilldown(new Date(this.x).toISOString().split('T')[0])
            }
          }
        }
      }
    ]

    let plotLines = []
    if (total > 0) {
      plotLines = [
        {
          zIndex: 50,
          color: controller.darkMode ? controller.tailwindColors.blue[200] : controller.tailwindColors.blue[900],
          value: controller.parsedGraphData.weekly_avg,
          dashStyle: 'ShortDash',
          width: 2
        }
      ]
    }

    return {
      chart: {
        type: 'areaspline',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        plotBorderWidth: 0,
        events: {
          click: function (e) {
            if (e.target.dataset.name === 'drilldown' && controller.optionsValue.metrics_url) {
              controller.openMetricDrilldown(new Date(Number(e.target.dataset.value)).toISOString().split('T')[0])
            }
          }
        }
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'datetime',
        tickInterval: tickInterval,
        title: { text: null },
        tickLength: 0,
        labels: {
          step: controller.parsedGraphData.data.length > 6 ? 3 : 1,
          formatter: function () {
            return Highcharts.dateFormat(controller.parsedGraphData.date_format, this.value)
          },
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: controller.tailwindColors.gray[100],
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        maxPadding: 0
      },
      yAxis: {
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
        title: {
          text: null
        },
        labels: {
          format: '${text}', /* eslint-disable-line no-template-curly-in-string */
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            return `${this.value}%`
          }
        },
        startOnTick: false,
        plotLines,
        min: 0,
        max: 100,
        endOnTick: true
      },
      plotOptions: {
        series: {
          connectNulls: true
        }
      },
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        stickOnContact: true,
        formatter: function () {
          return card({
            date: controller.parsedGraphData.date_range_display[this.x],
            incomplete: this.points[0].point.index === controller.parsedGraphData.data.length - 1,
            footer: controller.optionsValue.metrics_url && `<button data-value="${this.x}" data-name="drilldown" class="btn-tertiary text-xs mt-2 w-full">View participants</button>`,
            primaryValues: [
              primaryValue({ name: 'Review participation', value: `${this.y}%` })
            ]
          })
        },
        style: {
          zIndex: 100
        }
      },
      series
    }
  }
}
